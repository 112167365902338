import styled from '@emotion/styled';
import { css } from '@emotion/react';

import typography from '@wb/shared/theme/typography';

const PROPS_TO_EXCLUDE = ['loading', 'variant', 'size', 'fullWidth'];

const shouldForwardProp = (prop) => !PROPS_TO_EXCLUDE.includes(prop);

const sharedStyles = css`
  font-family: ${typography.fontFamily};
  font-weight: ${typography.weight.medium};
  letter-spacing: 0.01rem;
  line-height: 1.42;
  font-size: 1.4rem;
  border-radius: 0.3rem;
  cursor: pointer;
  border: none;
`;

const primaryStyles = css`
  ${sharedStyles};

  color: var(--palette-secondary-color);
  background-color: var(--palette-primary-color);

  &:hover:not(:disabled) {
    opacity: 0.5;
  }

  &:active:enabled {
    opacity: 1;
    filter: brightness(80%);
  }

  &:disabled {
    background-color: var(--palette-action-disabled);
    color: var(--palette-grey-darker);
    cursor: not-allowed;
  }
`;

const dangerStyles = css`
  ${sharedStyles};

  color: var(--palette-secondary-color);
  background-color: var(--palette-red);

  &:hover:not(:disabled) {
    opacity: 0.5;
  }

  &:active:enabled {
    opacity: 1;
    filter: brightness(80%);
  }

  &:disabled {
    background-color: var(--palette-action-disabled);
    color: var(--palette-grey-darker);
    cursor: not-allowed;
  }
`;

const secondaryStyles = css`
  ${sharedStyles};

  background-color: var(--palette-grey-lighter);
  color: var(--palette-primary-color);
  border: 1px solid var(--palette-grey);

  &:hover:not(:disabled) {
    background-color: var(--palette-grey);
    opacity: 0.5;
  }

  &:active:enabled {
    background-color: var(--palette-grey);
    opacity: 1;
    filter: brightness(80%);
  }

  &:disabled {
    background-color: var(--palette-white);
    color: var(--palette-grey-darker);
    cursor: not-allowed;
  }
`;

const linkStyles = css`
  ${sharedStyles};

  background-color: transparent;
  border: none;
  cursor: pointer;
  color: var(--palette-primary-color);
  text-decoration: underline;
`;

const smallStyles = css`
  padding: 0.5rem 1.6rem;
`;

const mediumStyles = css`
  padding: 0.9rem 2rem;
`;

const largeStyles = css`
  padding: 1.5rem 2rem;
`;

const fullWidthStyles = css`
  width: 100%;
`;

const variantStylesMapper = {
  primary: primaryStyles,
  secondary: secondaryStyles,
  danger: dangerStyles,
  link: linkStyles,
};

const sizeStylesMapper = {
  small: smallStyles,
  medium: mediumStyles,
  large: largeStyles,
};

export const NativeButton = styled('button', {
  shouldForwardProp,
})`
  ${({ variant }) => variantStylesMapper[variant]};
  ${({ size }) => sizeStylesMapper[size]};
  ${({ fullWidth }) => fullWidth && fullWidthStyles};
`;
