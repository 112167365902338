import React, {
  useEffect,
  useReducer,
  createContext,
  useContext,
  useCallback,
  useMemo,
  useState,
} from 'react';

// eslint-disable-next-line import/no-named-as-default
import Amplify, { Auth } from 'aws-amplify';
import { useQueryClient } from 'react-query';

import PropTypes from 'prop-types';

import useCustomer from '@wb/shared/state/customer/useCustomer';
import kyInstance from '@wb/shared/client/kyInstance';
import useAdvisor from '@wb/shared/state/advisor/useAdvisor';

import reducer, { ReducerActionTypes, initialState } from './reducer';

import amplifyConfig from './amplifyConfig';

import * as constants from './constants';

Amplify.configure(amplifyConfig);

export const UserContext = createContext();

export const UserProvider = ({ isAdvisor, ...props }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [userDetails, setUserDetails] = useState({});
  const queryClient = useQueryClient();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { ssoEnabled } = isAdvisor ? useAdvisor() : useCustomer();

  useEffect(() => {
    const loadAuthenticatedUser = async () => {
      try {
        dispatch({
          type: ReducerActionTypes.LOGIN_REQUEST,
        });
        const user = await Auth.currentAuthenticatedUser();
        setUserDetails(user.attributes);
        dispatch({
          type: ReducerActionTypes.LOGIN_SUCCESS,
          payload: user,
        });
      } catch (error) {
        dispatch({
          type: ReducerActionTypes.RESET_USER,
        });
      }
    };

    const loadSSOuser = async () => {
      try {
        dispatch({
          type: ReducerActionTypes.LOGIN_REQUEST,
        });
        const user = await kyInstance.get('user').json();
        setUserDetails(user);
        dispatch({
          type: ReducerActionTypes.LOGIN_SUCCESS,
          payload: {
            username: user.email,
            signInUserSession: { accessToken: { jwtToken: 'authToken' } },
          },
        });
      } catch (error) {
        clearStorage();
        dispatch({
          type: ReducerActionTypes.RESET_USER,
        });
      }
    };
    if (ssoEnabled) loadSSOuser();
    else loadAuthenticatedUser();
  }, [ssoEnabled]);

  const resetChallenge = useCallback(
    (resetUser = true) => {
      if (state.timeout) clearTimeout(state.timeout);
      if (resetUser) dispatch({ type: ReducerActionTypes.RESET_USER });
    },
    [state.timeout]
  );

  const signOut = useCallback(async () => {
    try {
      dispatch({ type: ReducerActionTypes.LOGOUT_REQUEST });
      if (ssoEnabled) clearStorage();
      else await Auth.signOut({ global: true });
      queryClient.clear();
      dispatch({ type: ReducerActionTypes.LOGOUT_SUCCESS });
    } catch (error) {
      dispatch({
        type: ReducerActionTypes.LOGOUT_FAILURE,
        payload: error,
      });

      throw error;
    }
  }, [queryClient, ssoEnabled]);

  const signIn = useCallback(
    async ({ email, password }) => {
      try {
        dispatch({ type: ReducerActionTypes.LOGIN_REQUEST });

        const [customerId] = window.location.host.split('.') || null;

        const user = await Auth.signIn({
          username: email.trim(),
          password,
          validationData: {
            customerId,
          },
        });

        setUserDetails(user.attributes);

        if (user.challengeName === constants.SMS_MFA_CHALLENGE) {
          const t = setTimeout(
            () => resetChallenge(),
            constants.SMS_MFA_TIMEOUT
          );

          dispatch({
            type: ReducerActionTypes.LOGIN_MFA_CHALLENGE_SHOW,
            payload: { user, timeout: t },
          });
        } else {
          dispatch({ type: ReducerActionTypes.LOGIN_SUCCESS, payload: user });
        }
      } catch (error) {
        dispatch({
          type: ReducerActionTypes.LOGIN_FAILURE,
          payload: error,
        });

        throw error;
      }
    },
    [resetChallenge]
  );

  const ssoSignIn = useCallback(async (idToken, authToken) => {
    try {
      dispatch({ type: ReducerActionTypes.LOGIN_REQUEST });

      localStorage.setItem(
        `${constants.COGNITO_KEY_PREFIX}.${process.env.REACT_APP_COGNITO_CLIENT_ID}.idToken`,
        idToken
      );
      localStorage.setItem(
        `${constants.COGNITO_KEY_PREFIX}.${process.env.REACT_APP_COGNITO_CLIENT_ID}.accessToken`,
        authToken
      );
      const user = await kyInstance.get('user').json();
      localStorage.setItem(
        `${constants.COGNITO_KEY_PREFIX}.${process.env.REACT_APP_COGNITO_CLIENT_ID}.LastAuthUser`,
        user
      );

      setUserDetails(user);
      dispatch({
        type: ReducerActionTypes.LOGIN_SUCCESS,
        payload: {
          username: user.email,
          signInUserSession: { accessToken: { jwtToken: 'authToken' } },
        },
      });
    } catch (error) {
      dispatch({
        type: ReducerActionTypes.LOGIN_FAILURE,
        payload: error,
      });

      throw error;
    }
  }, []);

  const completeChallenge = useCallback(
    async ({ challengeCode, user: challengeUser }) => {
      try {
        dispatch({ type: ReducerActionTypes.LOGIN_MFA_CHALLENGE_SUBMITTING });

        const user = await Auth.confirmSignIn(
          challengeUser,
          challengeCode,
          constants.SMS_MFA_CHALLENGE
        );

        if (state.timeout) resetChallenge(false);

        const { attributes } = await Auth.currentAuthenticatedUser();
        setUserDetails(attributes);
        dispatch({ type: ReducerActionTypes.LOGIN_SUCCESS, payload: user });
      } catch (error) {
        dispatch({
          type: ReducerActionTypes.LOGIN_MFA_CHALLENGE_FAILURE,
          payload: error,
        });

        throw error;
      }
    },
    [state.timeout, resetChallenge]
  );

  const changePassword = useCallback(async ({ oldPassword, newPassword }) => {
    try {
      dispatch({ type: ReducerActionTypes.CHANGE_PASSWORD_REQUEST });

      const user = await Auth.currentAuthenticatedUser();

      await Auth.changePassword(user, oldPassword, newPassword);

      dispatch({
        type: ReducerActionTypes.CHANGE_PASSWORD_SUCCESS,
        payload: user,
      });
    } catch (error) {
      dispatch({
        type: ReducerActionTypes.CHANGE_PASSWORD_FAILURE,
        payload: error,
      });

      throw error;
    }
  }, []);

  const getCognitoUser = useCallback(async ({ username, password }) => {
    try {
      dispatch({ type: ReducerActionTypes.COGNITO_USER_REQUEST });

      const user = await Auth.signIn(username, password);

      dispatch({ type: ReducerActionTypes.COGNITO_USER_SUCCESS });

      if (user.challengeName === constants.NEW_PASSWORD_CHALLENGE) {
        return user;
      }

      return null;
    } catch (error) {
      dispatch({
        type: ReducerActionTypes.COGNITO_USER_FAILURE,
        payload: error,
      });

      const { NOT_AUTHORIZED_CODE, USER_NOT_FOUND_CODE } = constants;

      if ([NOT_AUTHORIZED_CODE, USER_NOT_FOUND_CODE].includes(error.code)) {
        return null;
      }

      throw error;
    }
  }, []);

  const resetPassword = useCallback(
    async (email) =>
      await Auth.forgotPassword(email)
        .then(() => 'success')
        .catch((err) => err.code),
    []
  );

  const submitNewPassword = useCallback(
    async ({ email, token, newPassword }) => {
      await Auth.forgotPasswordSubmit(email, token, newPassword)
        .then(() =>
          dispatch({
            type: ReducerActionTypes.CHOOSE_PASSWORD_SUCCESS,
          })
        )
        .catch((error) => {
          dispatch({
            type: ReducerActionTypes.CHOOSE_PASSWORD_FAILURE,
            payload: error,
          });
          throw error;
        });
    },
    []
  );

  const choosePassword = useCallback(
    async ({ user, newPassword }) => {
      try {
        dispatch({ type: ReducerActionTypes.CHOOSE_PASSWORD_REQUEST });

        const updatedUser = await Auth.completeNewPassword(user, newPassword);

        if (updatedUser.challengeName === constants.SMS_MFA_CHALLENGE) {
          const t = setTimeout(
            () => resetChallenge(),
            constants.SMS_MFA_TIMEOUT
          );

          dispatch({
            type: ReducerActionTypes.LOGIN_MFA_CHALLENGE_SHOW,
            payload: { user: updatedUser, timeout: t },
          });
        } else {
          dispatch({
            type: ReducerActionTypes.CHOOSE_PASSWORD_SUCCESS,
            payload: updatedUser,
          });
        }
      } catch (error) {
        dispatch({
          type: ReducerActionTypes.CHOOSE_PASSWORD_FAILURE,
          payload: error,
        });

        throw error;
      }
    },
    [resetChallenge]
  );

  const updateUser = useCallback(
    (user) =>
      dispatch({ type: ReducerActionTypes.UPDATE_USER, payload: { user } }),
    []
  );

  const context = useMemo(
    () => ({
      ...state,
      isAuthenticated: !!state.user && !!state.authToken,
      userDetails,
      isAdvisor,
      actions: {
        signIn,
        signOut,
        changePassword,
        getCognitoUser,
        choosePassword,
        completeChallenge,
        resetChallenge,
        resetPassword,
        submitNewPassword,
        ssoSignIn,
        updateUser,
      },
    }),
    [
      state,
      userDetails,
      isAdvisor,
      signIn,
      signOut,
      changePassword,
      getCognitoUser,
      choosePassword,
      completeChallenge,
      resetChallenge,
      resetPassword,
      submitNewPassword,
      ssoSignIn,
      updateUser,
    ]
  );

  return <UserContext.Provider value={context} {...props} />;
};

UserProvider.propTypes = {
  isAdvisor: PropTypes.bool,
};

export const useUser = () => {
  return useContext(UserContext);
};

export default useUser;

const clearStorage = () =>
  Object.entries(localStorage)
    .map(([key]) => key)
    .filter((key) =>
      key.startsWith(
        `${constants.COGNITO_KEY_PREFIX}.${process.env.REACT_APP_COGNITO_CLIENT_ID}`
      )
    )
    .forEach((key) => localStorage.removeItem(key));
