import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';

import kyInstance from '@wb/shared/client/kyInstance';

import advisorIdFromURL from '@wb/shared/utils/advisorIdFromURL';

export const AdvisorContext = createContext();

export const AdvisorProvider = ({ ...rest }) => {
  const queryFn = () => kyInstance.get('advisor-tenant-data').json();

  const { isLoading, error, data } = useQuery('advisor-tenant-data', queryFn, {
    suspense: true,
  });

  if (data) {
    data.advisorId = advisorIdFromURL;
  }

  useEffect(() => {
    if (data?.theme) {
      const primaryColor = data.theme.primary;
      const secondaryColor = data.theme.secondary;

      if (primaryColor) {
        document.documentElement.style.setProperty(
          '--palette-primary-color',
          primaryColor
        );
      }

      if (secondaryColor) {
        document.documentElement.style.setProperty(
          '--palette-secondary-color',
          secondaryColor
        );
      }
    }

    if (data?.logo) {
      document.getElementById('apple-favicon')?.setAttribute('href', data.logo);
      document.getElementById('favicon')?.setAttribute('href', data.logo);
    }
  }, [data]);

  const context = useMemo(
    () => ({
      isLoading,
      error,
      ...data,
    }),
    [data, error, isLoading]
  );

  if (error) {
    throw error;
  }

  return <AdvisorContext.Provider value={context} {...rest} />;
};

export const useAdvisor = () => {
  return useContext(AdvisorContext);
};

export default useAdvisor;
