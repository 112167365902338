import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import Loader from '@wb/shared/components/loader/Loader';

import { NativeButton } from './Button.styles';

const Button = forwardRef((props, ref) => {
  const {
    loading,
    disabled,
    variant = 'primary',
    size = 'medium',
    children,
    ...remainingProps
  } = props;

  const content =
    typeof children === 'string' ? <span>{children}</span> : children;

  return (
    <NativeButton
      ref={ref}
      variant={variant}
      size={size}
      disabled={disabled || loading}
      {...remainingProps}
    >
      {loading ? <Loader size={size} /> : content}
    </NativeButton>
  );
});

Button.displayName = 'Button';

Button.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  variant: PropTypes.oneOf(['danger', 'primary', 'secondary', 'link']),
  size: PropTypes.oneOf(['large', 'medium', 'small']),
};

export default Button;
